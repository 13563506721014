import React from "react";

function getButtonTypeClassName(type) {
  switch (type) {
    case "primary":
      return type;
    case "secondary":
      return type;
    case "text":
      return type;
    default:
      return "primary";
  }
}

const Button = ({ className, onClick, href, style, type, children }) => {
  if (!href) {
    return (
      <button
        className={`m-button ${getButtonTypeClassName(type)} ${className}`}
        onClick={onClick}
        style={{ ...style }}
      >
        {children}
      </button>
    );
  } else {
    return (
      <a
        href={href}
        className={`m-button ${getButtonTypeClassName(type)} ${className}`}
        onClick={onClick}
        style={{ ...style }}
      >
        {children}
      </a>
    );
  }
};

export default Button;
