import React from "react";
import { graphql, Link } from "gatsby";
import Slider from "react-slick";
import { useWindowSize } from "../hooks/useWindowSize";
import Img from "../components/Img";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Layout from "../components/Layout";
import SectionTitle from "../components/SectionTitle";
import Button from "../components/Button";
import SlickArrow from "../components/SlickArrow";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WorkWithImages = ({ workWithImages }) =>
  workWithImages.map((imageObj, index) => (
    <Img
      className="image"
      image={imageObj.image_icon}
      key={index}
      objectFit="contain"
    />
  ));

export const HomePageTemplate = ({
  cover_section,
  description_section,
  work_with_section,
  recently_published_section,
  featured_section,
  blogNodes,
}) => {
  // console.debug({
  //   cover_section,
  //   description_section,
  //   work_with_section,
  //   recently_published_section,
  //   featured_section,
  // });

  const { width } = useWindowSize();

  const {
    paragraph: coverParagraph,
    background_image: coverImage,
    redirect_button: coverButton,
  } = cover_section;

  const {
    title: descriptionTitle,
    paragraph: descriptionParagraph,
  } = description_section;

  const {
    title: workWithTitle,
    images: workWithImages = [],
  } = work_with_section;

  const {
    title: recentlyPublishedTitle,
    enabled: recentlyPublishedEnabled,
  } = recently_published_section;

  const {
    title: featuredSectionTitle,
    enabled: featuredSectionEnabled,
    featured_items: featuredSectionItems,
  } = featured_section;

  const sliderSetting = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: width <= 900 ? 1 : 3,
    slidesToScroll: 1,
    nextArrow: <SlickArrow direction="right" />,
    prevArrow: <SlickArrow direction="left" />,
  };

  // console.debug(descriptionParagraph);
  let _featuredSectionItems = [];
  if (featuredSectionItems) {
    _featuredSectionItems =
      width <= 768 ? [featuredSectionItems[0]] : featuredSectionItems;
  }

  return (
    <div className="home">
      <div className="cover">
        <div className="cover-bg-img">
          <img
            src={
              !!coverImage.childImageSharp
                ? coverImage.childImageSharp.fluid.src
                : coverImage
            }
            alt=""
          />
        </div>
        <div className="cover-wrapper">
          <h1>{coverParagraph}</h1>
          <Button
            href={coverButton.button_link}
            type="secondary"
            className="cover-button"
          >
            {coverButton.button_text}
          </Button>
        </div>
      </div>
      <section className="description-section section is-medium">
        <div>
          <h2>{descriptionTitle}</h2>
          <p
            className="column"
            dangerouslySetInnerHTML={{ __html: descriptionParagraph }}
          />
        </div>
      </section>
      <section className="workwith-section section is-medium">
        <SectionTitle heading={workWithTitle} subHeading="Featured" />
        <div className="image-list">
          <WorkWithImages workWithImages={workWithImages} />
        </div>
      </section>
      {recentlyPublishedEnabled ? (
        <section className="recently-section section is-medium">
          <SectionTitle heading={recentlyPublishedTitle} subHeading="" />
          <div className="slider-container">
            <Slider {...sliderSetting}>
              {blogNodes.map((node) => (
                <div className="recent-item" key={node.id}>
                  <Link to={node.fields.slug}>
                    <PreviewCompatibleImage
                      imageInfo={node.frontmatter.featuredimage}
                      alt={node.frontmatter.title}
                      style={{ height: "572px", borderRadius: "0" }}
                    />
                    <p className="has-text-centered">
                      {node.frontmatter.title}
                    </p>
                  </Link>
                </div>
              ))}
            </Slider>
          </div>
        </section>
      ) : null}
      {featuredSectionEnabled ? (
        <section className="featured-section section is-medium">
          <SectionTitle heading={featuredSectionTitle} subHeading="FEATURED" />
          <div className="columns">
            {_featuredSectionItems.map((item, index) => (
              <a className="column is-one-quarter" href={item.url} key={index}>
                <PreviewCompatibleImage
                  imageInfo={item.image}
                  alt={item.title}
                />
                <p className="has-text-centered">{item.title}</p>
              </a>
            ))}
          </div>
        </section>
      ) : null}
    </div>
  );
};

const HomePage = ({ data }) => {
  const { config, blogs } = data;
  const homeFront = config.frontmatter;
  const blogNodes = blogs.edges.map((edge) => edge.node);

  return (
    <Layout>
      <HomePageTemplate
        cover_section={homeFront.cover_section}
        description_section={homeFront.description_section}
        work_with_section={homeFront.work_with_section}
        recently_published_section={homeFront.recently_published_section}
        featured_section={homeFront.featured_section}
        blogNodes={blogNodes}
      />
    </Layout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query HomePageTemplate {
    config: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        templateKey
        cover_section {
          paragraph
          background_image
          redirect_button {
            button_text
            button_link
          }
        }
        description_section {
          title
          paragraph
        }
        work_with_section {
          title
          images {
            image_icon
            image_alt
          }
        }
        recently_published_section {
          title
          enabled
        }
        featured_section {
          title
          enabled
          featured_items {
            title
            url
            image
          }
        }
      }
    }
    blogs: allMarkdownRemark(
      limit: 6
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { type: { eq: "blog-post" }, draft: { ne: true } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            featuredimage
          }
        }
      }
    }
  }
`;
